import React, { useState } from "react"
import Reveal from "react-reveal/Reveal"
import { Link } from "gatsby"

import styles from "./styles.module.less"

const Intro = () => {
  const [showMore, setShowMore] = useState(false)
  const [hideReadMore, setHideMore] = useState(false)

  const handleClick = e => {
    setShowMore(true)
    setHideMore(true)
  }

  return (
    <section className={styles.Intro}>
      <h1>
        Thesis is a digital agency{` `}
        <br className={styles.mobileHide} />
        <span className={styles.nowrapDesktop}>
          that’s hell-bent on humanizing
        </span>
        {` `}
        <br className={styles.mobileHide} />
        the relationship between{` `}
        <br className={styles.mobileHide} />
        <span stlye={styles.nowrapDesktop}>people&nbsp;and&nbsp;brands.</span>
      </h1>
      <p>
        We’ve felt this unforgiving year. From the things we can't control:{` `}
        <em>COVID</em>. <em>Wildfires</em>.{` `}
        <br className={styles.mobileHide} />
        To the things we can absolutely do something about: <em>Injustice</em>.
      </p>

      {!hideReadMore && (
        <p>
          <a onClick={handleClick} className={styles.ctaOut}>
            <span>Read more</span>.
          </a>
        </p>
      )}

      {showMore && (
        <Reveal>
          <p>
            While we don't believe the flip of the calendar will suddenly flip
            the script, we do believe the work we do each day can play a part in
            progress. The digital products we make can help brands keep it real
            and relevant for the people enduring these unreal times, and the
            equity initiatives we exercise can educate our own house, helping us
            to stay aware, empathetic, and ever on course.
          </p>

          <p>
            Through our work and beyond our work, we’re committed to doing right
            by our people, our clients, and our community. And although our
            Diversity, Equity, and Inclusion (DEI) journey has been
            winding—tested, tried, and renewed across the years—it’s ongoing and
            everlasting.
          </p>

          <p>
            So, on we go. Real people making real work. Step by very intentional
            step.{` `}
            <br className={styles.mobileHide} />
            Eyes forward, hearts all around, and upward as one.
          </p>
        </Reveal>
      )}
    </section>
  )
}

export default Intro
