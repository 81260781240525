import React from "react"

import blue from "../../img/taco-bell/blue-nachos.jpg"
import green from "../../img/taco-bell/green-nachos.jpg"
import thankyou from "../../img/taco-bell/thankyou.gif"

const textX = 765

const TacoBell = ({ className }) => {
  return (
    <svg
      className={className}
      width="1316"
      height="945"
      viewBox="0 0 1316 945"
      fill="none"
    >
      <image href={blue} x="594" width="600" height="599.01" />
      <image href={green} x="84" y="397" width="700" height="466.667" />
      <image href={thankyou} y="46" width="754" height="427" />
      <text
        x={textX}
        y="665"
        width="550"
        height="300"
        style={{
          fontFamily: "CircularStd Book",
          fill: "#ffffff",
          fontSize: "40px",
        }}
      >
        <tspan fontFamily="CircularStd Black" fontSize="50">
          Taco Bell
        </tspan>
        <tspan dy="1.2em" x={textX} fontSize="40">
          Personalizing ecomm
        </tspan>
        <tspan dy="1.2em" x={textX} fontSize="40">
          for everybody’s favorite
        </tspan>
        <tspan dy="1.2em" x={textX} fontSize="40">
          taco shop.
        </tspan>
      </text>
    </svg>
  )
}

export default TacoBell
