import React, { Component } from "react"

import styles from "./styles.module.less"

import emdash from "../../img/emdash.png"

export default class Clients extends Component {
  render() {
    return (
      <section className={styles.Clients}>
        <div className={styles.Header}>
          <h1>
            Thesis solves
            <br />
            for the greats.
          </h1>
          <nav className={styles.links}>
            <a href="/work" className={styles.cta}>
              <span>See the work</span>
              <img alt="" src={emdash} />
            </a>
          </nav>
        </div>
      </section>
    )
  }
}
