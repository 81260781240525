import React from "react"

import pcrScroll from "../../img/adobe/Adobe-pcr-scroll.jpg"
import web from "../../img/adobe/Adobe-web.jpg"
import strategy from "../../img/adobe/Adobe-strategy.jpg"
import smile from "../../img/adobe/Adobe-smile.jpg"

const Adobe = ({ className }) => {
  const textX = "165"
  return (
    <svg
      className={className}
      width="1438"
      height="790"
      viewBox="0 0 1438 790"
      fill="none"
    >
      <image href={pcrScroll} x="37" y="317" width="479" height="223" />
      <image href={web} x="909" y="232" width="529" height="308" />
      <image href={strategy} y="540" width="1000" height="250" />
      <image href={smile} x="470" y="13" width="439" height="718" />
      <text
        x={textX}
        y="105"
        width="550"
        height="300"
        style={{
          fontFamily: "CircularStd Book",
          fill: "#ffffff",
          fontSize: "40px",
        }}
      >
        <tspan fontFamily="CircularStd Black" fontSize="50">
          Adobe
        </tspan>
        <tspan dy="1.2em" x={textX} fontSize="40">
          Approaching SEO
        </tspan>
        <tspan dy="1.2em" x={textX} fontSize="40">
          as modern, elevated,
        </tspan>
        <tspan dy="1.2em" x={textX} fontSize="40">
          elegant editorial.
        </tspan>
      </text>
    </svg>
  )
}

export default Adobe
