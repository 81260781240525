import React, { Component } from "react"
import { Link } from "gatsby"

import Adobe from "./components/Adobe"
import TacoBell from "./components/TacoBell"
import KnowMeNow from "./components/KnowMeNow"

import styles from "./styles.module.less"

export default class Work extends Component {
  render() {
    return (
      <section className={styles.Work} id="work">
        <div className={styles.sticky}>
          <header>
            <h1>
              Our service
              <br className={styles.hide} /> is solving*.
            </h1>
            <p>
              <em>*And making. Lots of making.</em>
            </p>
          </header>
        </div>
      </section>
    )
  }
}

export const Slides = () => {
  return (
    <div className={styles.Slides}>
      <div className={styles.stage}>
        <Slide1 />
        <Slide2 />
        <Slide3 />
      </div>
    </div>
  )
}

export const Slide1 = () => {
  return (
    <div className={styles.slide}>
      <Link to="case-studies/adobe-seo">
        <Adobe className={styles.main} />
      </Link>
    </div>
  )
}

export const Slide2 = () => {
  return (
    <div className={styles.slide}>
      <Link to="/case-studies/tacobell">
        <TacoBell className={styles.main} />
      </Link>
    </div>
  )
}

export const Slide3 = () => {
  return (
    <div className={styles.slide}>
      <Link to="/case-studies/know-me-now">
        <KnowMeNow className={styles.main} />
      </Link>
    </div>
  )
}
