import React from "react"

import logo from "../../img/kmn/logo.png"
import fatherChild from "../../img/kmn/father-child.png"
import yellowBubble from "../../img/kmn/yello-bubble.png"
import orangeBubble from "../../img/kmn/orange-bubble.png"

const textX = 242

const KnowMeNow = ({ className }) => {
  return (
    <svg
      className={className}
      width="1440"
      height="891"
      viewBox="0 0 1440 891"
      fill="none"
    >
      <image href={logo} x="45" y="390" width="704" height="501" />
      <image href={fatherChild} x="679" width="761" height="593" />
      <text
        x={textX}
        y="137"
        width="550"
        height="300"
        style={{
          fontFamily: "CircularStd Book",
          fill: "#ffffff",
          fontSize: "40px",
        }}
      >
        <tspan fontFamily="CircularStd Black" fontSize="50">
          Know Me Now
        </tspan>
        <tspan dy="1.2em" x={textX} fontSize="40">
          Putting a name and a face
        </tspan>
        <tspan dy="1.2em" x={textX} fontSize="40">
          to a critical social movement.
        </tspan>
      </text>
      <image href={yellowBubble} y="191" width="242" height="265" />
      <image href={orangeBubble} x="634" y="503" width="298" />
    </svg>
  )
}
export default KnowMeNow
